<template>
  <App
    title="排程列表"
    left-arrow
    class="rank"
  >
    <Tabs
      v-model="tabActive"
      title-active-color="#DA3738"
      slot="extra"
      @change="getData"
    >
      <Tab
        :title="$i.title"
        v-for="$i in tabsData"
        :key="$i.label"
        :name="$i.label"
        :badge="$i.number"
      />
    </Tabs>
    <PullRefresh
        v-show="hasDate"
        v-model="loading"
        @refresh="onRefresh"
      >
        <List
          v-model="loading"
          :finished="finished"
          finished-text="No more items."
          @load="getData"
        >
          <CardItem
            v-for="$items in filterStatusList"
            :key="$items.id"
            :cardInfo="$items"
            :type="tabActive"
            @click.native="detail($items)"
          />
        </List>
      </PullRefresh>
  </App>
</template>

<script>
import { Tab, Tabs, PullRefresh, List, Toast } from 'vant';
import dayjs from 'dayjs'

import { tabsData } from './attr.js'
import CardItem from './components/card/index'
import 'vant/lib/nav-bar/style'
import '@/style/vant-patch.less'
import './index.less'
import { checkTime } from '@/api/testdrive/index'
export default {
  name: 'rank',
  data () {
    return {
      showResult: false,
      hasDate: true,
      loading: false,// 下拉刷新时的loading是否展示
      finished: true,
      radioVal: '1',
      list: [], // 请求过来的排程列表数据
      tabActive: 'all', // 当前tab选中的状态
      tabsData: [], // 排程状态集合
      filterStatusList: [], // 根据不同状态筛选出来的排程列表
      startTime: '',
      endTime: '',
      storeCode: '',
      tabName: 'all'
    }
  },
  components: {
    Tab,
    Tabs,
    PullRefresh,
    List,
    CardItem
  },
  methods: {
    // 点击具体的排程跳转至详情页面
    detail (items) {
      const id = items.eosTestDriveId
      this.$router.push({
        path: '/testDriveDetail',
        query: { id }
      })
    },
    getQuery () {
      this.startTime = this.$route.query['startTime'] ? this.$route.query['startTime'] : dayjs(new Date()).format('YYYY-MM-DD')
      this.endTime = this.$route.query['endTime'] ? this.$route.query['endTime'] : dayjs(new Date()).format('YYYY-MM-DD')
      this.storeCode = this.$route.query['storeCode'] ? this.$route.query['storeCode'] : this.$storage.get('saleDlr')[0]['code']
    },
    // 获取排程列表
    async getTestdriveList () {
      /* Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true
      }) */
      const res = await checkTime({
        storeCode: this.storeCode,// 门店id
        startDate: this.startTime,// 开始时间 格式YYYY-MM-DD
        endDate: this.endTime, // 结束时间
        vehicleId: '' //车辆id非必传
      })
      Toast.clear()
      this.tabsData.forEach(el => {
        el['children'] = []
      })
      if (res.success) {
        res.data.forEach(item => {
          item['appointmentDate'] = dayjs(item['appointmentDate']).format('YYYY/MM/DD')
          this.tabsData.forEach(status => {
            if (item.testDriveStatus === status.label) {
              item['tag'] = status.title
              status['children'].push(item)
              status['number'] = status['children'].length
            }
            if (status.label === 'all') {
              status['number'] = res.data.length
            }
          })
          this.$storage.get('saleDlr').forEach(el => {
            if (item['eosStoreCode'] === el.code) {
              item['eosStoreCode'] = el.name
            }
          })
        })
        this.list = [...res.data]
        this.filterStatusList = [...res.data]
      } else {
        Toast(res.msg)
      }

    },
    // tab标签点击事件，根据不同状态筛选出对应状态所包含的数据
    getData (e) {
      this.tabName = e
      if (e !== 'all') {
        this.filterStatusList = this.list.filter(item => {
          return item['testDriveStatus'] === e
        })
      } else {
        this.filterStatusList = this.list
      }
    },
    // 下拉刷新事件
    async onRefresh () {
      await this.getTestdriveList()
      this.loading = false
      this.getData(this.tabName)
    },
    async init () {
      await this.getQuery()
      this.tabsData = tabsData
      this.tabActive = this.tabsData[0].label
      this.getTestdriveList()
    },
  },
  mounted () {
    this.init()
  }
}
</script>
<style scoped>
.van-info {
  right: -14px !important;
}
</style>
