/*
 * @Author: zzz
 * @Date: 2021-01-26 15:49:46
 * @LastEditTime: 2021-02-25 16:54:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \04eos-app\src\pages\rank\attr.js
 */
export const tabsData = [
  {
    title: '全部',
    label: 'all',
    children: []
  },
  {
    title: '已排程',
    label: 'SCHEDULED',
    children: []
  },
  {
    title: '已确认',
    label: 'CONFIRMED',
    children: []
  },
  {
    title: '已逾期',
    label: 'OVERDUE',
    children: []
  },
  {
    title: '已取消',
    label: 'CANCELLED',
    children: []
  },
  {
    title: '已完成',
    label: 'COMPLETED',
    children: []
  }
]
